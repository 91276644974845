<template>
    <div>
        <title-bar :title="$t('navigations.receipts')"/>
        <div v-if="rows" class="custom-table">
            <advanced-filter
                :fields-array="filterFields"
                :action="showFilterModal"
                @clearAction="showFilterModal = false"
                @paramsChange="setAdvancedParams"
            />
            <table-actions
                :actions="['search', 'addBtn', 'perPage', 'advancedFilter']"
                :addBtnText="$t('messages.receiptsCreate')"
                :addBtnLink="$helper.getAddUrl('receipts')"
                @onSearchInput="inputChange"
                @perPageChange="perPageChange"
                @filterChange="showFilterModal = true"
                :defaultPerPage="perPage"
            />
            <table-lite
                :is-slot-mode="true"
                :is-loading="isLoading"
                :columns="columns"
                :rows="rows"
                :sortable="sortable"
                @do-search="setSort"
                @is-finished="isLoading = false"
            >
                <template v-slot:referenceNumber="data">
                    {{ data.value.referenceNumber }}
                </template>
                <template v-slot:date="data">
                    {{ $helper.formatDate(data.value.date) }}
                </template>
                <template v-slot:station="data">
                    {{ data.value.station ? data.value.station.name : '' }}
                </template>
                <template v-slot:employee="data">
                    <span v-if="'employee' in data.value">
                        {{ data.value.employee.givenName + " " + data.value.employee.familyName }}
                    </span>
                </template>
                <template v-slot:category="data">
                    {{ $helper.getEnumTranslation('receipt_category', data.value.category, $i18n.locale) }}
                </template>
                <template v-slot:description="data">
                    {{ data.value.description }}
                </template>
                <template v-slot:amount="data">
                    {{ data.value.amount }}
                </template>
                <template v-slot:status="data">
                    <b-badge :variant="statusClass(data.value.status)">{{ data.value.status }}</b-badge>
                </template>
                <template v-slot:closedBy="data">
                    <b-badge :variant="data.value.closedBy ? 'success':'danger'">
                        {{ data.value.closedBy ? "Yes" : "No" }}
                    </b-badge>
                </template>
                <template v-slot:file="data">
                    <view-btn v-if="'url' in data.value" :path="data.value.url"/>
                </template>
                <template v-slot:actions="data">
                    <div class="just-buttons">
                        <edit-btn
                            :path="$helper.getEditUrl('receipts', data.value.id)"
                        />
                        <delete-btn @pressDelete="deleteReceipt(data.value['@id'])"/>
                    </div>
                </template>
            </table-lite>
            <table-pagination
                v-model="currentPage"
                :total="pages"
                :per-page="perPage"
                @input="onChangePage"
            />
        </div>
    </div>
</template>

<script>

import TitleBar from "@/components/TitleBar.vue";
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import EditBtn from "@/components/Buttons/EditBtn.vue";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";
import ViewBtn from "@/components/Buttons/ViewBtn.vue";
import ResourceUtils from "@/mixins/ResourceUtils";
import AdvancedFilter from "@/components/Table/AdvancedFilter.vue";

export default {
    name: "AdminTravellingListing",
    props: {
        widget: {type: Boolean, default: false}
    },
    components: {
        TableLite,
        TablePagination,
        TableActions,
        EditBtn,
        DeleteBtn,
        ViewBtn,
        TitleBar,
        AdvancedFilter
    },
    mixins: [ResourceUtils],
    data() {
        return {
            apiUrl: this.$store.state.apiUrl,
            search: "",
            isLoading: false,
            rows: null,
            perPage: this.widget ? 5 : this.$store.state.defaultPerPage,
            currentPage: 1,
            pages: 0,
            showFilterModal: false,
            filterFields: [
                {
                    label: 'forms.date',
                    name: "date",
                    value: null,
                    type: "range"
                },
                {
                    label: 'forms.category',
                    name: "category",
                    value: null,
                    type: "enum",
                    enumName: "receipt_category"
                },
                {
                    label: 'forms.station',
                    name: "station",
                    value: null,
                    type: "entity",
                    entityName: "$Stations"
                },
                {
                    label: 'forms.employee',
                    name: "employee",
                    value: null,
                    type: "employee"
                },
            ],
            advancedParams: [],
            sortable: {
                order: "createdAt",
                sort: "desc",
            },
            columns: [
                {
                    label: '#',
                    field: "id",
                    sortable: true,
                },
                {
                    label: this.$t("forms.number"),
                    field: "referenceNumber",
                    sortable: true,
                },
                {
                    label: this.$t("forms.date"),
                    field: "date",
                    sortable: true,
                },
                {
                    label: this.$t("forms.station"),
                    field: "station",
                    sortable: true,
                },
                {
                    label: this.$t("forms.category"),
                    field: "category",
                    sortable: true,
                },
                {
                    label: this.$t("forms.description"),
                    field: "description",
                    sortable: false,
                },
                {
                    label: this.$t("forms.amount"),
                    field: "amount",
                    sortable: true,
                },
                {
                    label: this.$t("forms.currency"),
                    field: "currency",
                    sortable: true,
                },
                {
                    label: this.$t("forms.employee"),
                    field: "employee",
                    sortable: true,
                },
                {
                    label: this.$t("forms.status"),
                    field: "status",
                    sortable: true,
                },
                {
                    label: this.$t("forms.closed"),
                    field: "closedBy",
                    sortable: true,
                },
                {
                    label: this.$t("forms.file"),
                    field: "file",
                    sortable: false,
                },
                {
                    label: this.$t("forms.actions"),
                    field: "actions",
                    sortable: false,
                    width: "80px",
                },
            ],
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            const params = {
                itemsPerPage: this.perPage,
                page: this.currentPage,
            };
            params["order[" + this.sortable.order + "]"] = this.sortable.sort;
            if (this.search && this.search.length) {
                params.search = this.search;
            }
            this.advancedParams.forEach(element => {
                if (element.value !== '' && element.value !== null) {
                    params[element.name] = element.value
                }
            });
            this.$Receipts.getCollection({params}, 'receipts_list').then((response) => {
                this.pages = response.data["hydra:totalItems"];
                this.rows = response.data["hydra:member"];
            });
        },
        setSort(offset, limit, order, sort) {
            this.sortable.sort = sort;
            this.sortable.order = order;
            if (order === 'branch' || order === 'station') {
                this.sortable.order = order + '.name';
            }
            if (order === 'employee') {
                this.sortable.order = order + '.familyName';
            }
            this.load();
        },
        setAdvancedParams(data) {
            this.advancedParams = data
            this.load()
        },
        onChangePage(page) {
            this.currentPage = page;
            this.load();
        },
        deleteReceipt(url) {
            this.deleteByUrl(this.$Receipts, url, this.$t("messages.receiptDeleted"), null, this.load, this.error);
        },
        statusClass(status) {
            let className = 'primary'
            if (status === 'APPROVED') {
                className = 'success'
            }
            if (status === 'DECLINED') {
                className = 'danger'
            }
            return className
        },
        inputChange(value) {
            this.currentPage = 1;
            this.search = value;
            this.load();
        },
        onPageChange() {
            this.load();
        },
        perPageChange(num) {
            this.currentPage = 1;
            this.perPage = num;
            this.load();
        },
        error() {
            this.$emit("clearAction");
        },
    },
};
</script>

